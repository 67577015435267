import React, {useState} from 'react';

const Regalo = () => {
  
  const [mostrarSobre, setMostrarSobre] = useState(false);

  const handleIconClick = () => {
    setMostrarSobre(!mostrarSobre);
  };
  return(
    <div className='regalo'>

        <p className='p-regalo datos'>¡Lo más importante es que vengan!</p>
        <span onClick={handleIconClick} className='icono-regalo'>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-gift" width="60" height="60" viewBox="0 0 24 24" stroke-width="1.5" stroke="#f1eded" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 8m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
            <path d="M12 8l0 13" />
            <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
            <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
          </svg>
        </span>
        <p className='datos p-plata'>Y para quienes deseen hacernos un regalo de bodas, nos sería muy útil que nos ayudaran con nuestros proyectos.</p>
      <div className="ver-mas-container">
        <button onClick={handleIconClick} className="ver-mas-button">Ver más</button>
      </div>
      {mostrarSobre && (
        <div className="sobre-container">
          <span onClick={handleIconClick} className='icono-sobre'>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="60"  height="60"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
              <path d="M3 7l9 6l9 -6" />
            </svg>
          </span>
          <p className='p-regalo datos'>El día de la fiesta</p>
        </div>
      )}
    </div>
  )
}
export default Regalo;